
<template>
    <div>
        نمادهای هم گروه<br>
        <div v-for="(item, index) in stock_category" :key="index" v-if="stock_category">
        نماد : {{ item.symbol }}<br>
        آخرین : {{ item.PDrCotVal }} ({{ item.last_change}})<br>
        حجم : {{ item.QTotTran5J }}<br>
        ارزش : {{ item.QTotCap }}<br>
        ارزش بازار : {{ item.arzesh_bazar }}<br>
        نوع دارایی : {{ item.market_fa }}<br>
        تاریخ : {{ item.time }}<br>
        #############################
        </div>
    </div>
</template>

<script>
export default {
    name: 'StockCategory',
    data() {
        return {
            stock_category : null,
        }
    },
    mounted() {
        this.getProfileCategory();
    },
    methods: {
        getProfileCategory() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-category/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_category = api_response.data.response.category.body.response.items;
                        this.widgetLoad = false;
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>